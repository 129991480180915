import { Box, Button, Image, Markdown, Text } from "grommet"
import { Linkedin } from "grommet-icons"
import * as React from "react"

import Profile from "../assets/profile.jpg"

import siteConfig from "../../site-config"

export default () => (
  <Box
    pad={{
      horizontal: "medium",
      top: "xlarge",
      bottom: "medium"
    }}
    margin="small"
    wrap={true}
    overflow="hidden"
    align="center"
  >
    <Box
      margin={{ bottom: "small", left: "none", right: "small" }}
      height="small"
      width="small"
      overflow="hidden"
      round="full"
    >
      <Image
        fit="cover"
        title={siteConfig.author}
        alt={siteConfig.author}
        src={Profile}
      />
    </Box>
    <Box direction="row-responsive" align="center">
      <Text weight="bold" size="large" color="text">
        {siteConfig.author}
      </Text>
      <Button
        href="https://www.linkedin.com/in/bill-farrar-8233729/"
        icon={<Linkedin size="medium" />}
      />
    </Box>
    <Box overflow="hidden">
      <Text size="small" color="text">
        Full stack developer with over 20 years in web technologies and over 30
        years as a software engineer and designer. Senior systems analyst and
        team lead, spending time running projects and mentoring peers.
      </Text>
    </Box>
  </Box>
)
