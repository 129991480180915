import React from "react"

import { Box, Grid } from "grommet"

import Layout from "./Layout"
import SiteFooter from "./SiteFooter"
import SiteHeader from "./SiteHeader"
import Sidebar from "./Sidebar"

const Page = ({ children }) => (
  <Layout>
    <Grid
      rows={["min-content", "1fr", "min-content"]}
      columns={["1fr", "3fr", "1fr", "1fr"]}
      areas={[
        ["left", "header", "sidebar", "right"],
        ["left", "main", "sidebar", "right"],
        ["left", "footer", "sidebar", "right"]
      ]}
    >
      <Box gridArea="header">
        <SiteHeader />
      </Box>
      <Box gridArea="main">{children}</Box>
      <Box gridArea="sidebar" background="#eeeeee">
        <Sidebar />
      </Box>
      <Box gridArea="footer">
        <SiteFooter />
      </Box>
    </Grid>
  </Layout>
)

export default Page
