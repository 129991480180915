module.exports = {
  //
  // Change information to your own:
  //
  // Site Info:
  title: "Bill Farrar",
  description: "Portfolio Site and Blog",
  siteUrl: "https://www.otbp.org",
  footerText: "**2020**<br><br>Welcome to my site", // You can use Markdown markup here

  // Blog Author Info:
  author: "Bill Farrar",

  // Services:
  googleAnalyticsTrackingId: `ADD YOUR TRACKING ID HERE`,

  // Site Style:
  lightTheme: "grommet", // grommet | dark | base | dxc | aruba
  // leave darkTheme blank if you don’t need a theme switch
  darkTheme: "", // '' | grommet | dark | base | dxc | aruba
  font: "", // Roboto
  fontHeadings: "", // Merriweather
  brandColor: "" // #7D4CDB
}
