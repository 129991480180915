import React from "react"

import { Grommet } from "grommet"
import { grommet } from "grommet/themes"
import { createGlobalStyle } from "styled-components"
import { connect } from "react-redux"

import siteConfig from "../../site-config"

import siteTheme from "../site-theme"

const GlobalStyle = createGlobalStyle`
  img {
    max-width: 100%;
  }
  body {
    margin: 0;
  }
  a:hover {
    opacity: 0.9;
  }
`

const Theme = ({ children, themeType, themeSwitch }) => (
  <Grommet theme={grommet}>
    <Grommet theme={siteTheme}>
      <GlobalStyle />
      {children}
    </Grommet>
  </Grommet>
)

const mapStateToProps = ({ themeType }) => {
  return { themeType }
}

const mapDispatchToProps = dispatch => {
  return { themeSwitch: () => dispatch({ type: `CHANGE_THEME` }) }
}

const ConnectedTheme = connect(mapStateToProps, mapDispatchToProps)(Theme)

export default function Layout({ children }) {
  return <ConnectedTheme>{children}</ConnectedTheme>
}
